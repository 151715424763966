.Order_number{
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 18px;
      color: #403e3e;
  }

  .table_head{
      letter-spacing: 1.01px;
      font-size: 20px;
      font-weight: 500;
  }

                                                            
  .product_name{
      letter-spacing: 1.01px;
      font-size: 16px;
  }

  .product_price{
      letter-spacing: 1.01px;
      font-size: 16px; 
  }

  .sub_total {
      letter-spacing: 1.01px;
      font-size: 21px;
      /* margin-left: 22px; */
  }

  .order_qun{
      /* margin-left: 73px; */
            font-size: 18px;
  }

  .chf_chf{
      font-size: 18px;
  }

  @media (max-width: 768px){
    .drawer_titel_for_dine {
        font-size: 26px;
        margin-left: 75px;
    }}


    .swal2-styled.swal2-confirm {
        border: 0;
        border-radius: 0.25em;
        background: initial;
        background-color: #F25042;
        color: #fff;
        font-size: 1em;
    }


    /* .table_table_table{

    --bs-table-accent-bg: var(--bs-table-striped-bg);
         color: var(--bs-table-striped-color); 
    } */