.frame8__ {
      border: 1px solid;
      border-color: #d0d5dd;
      border-radius: 10px;
      height: 105px;
      position: relative;
      width: 100%;
     
    }
    
    @media screen and (max-width: 767px) {
      .frame8__ {
        margin-bottom: 10px;
      }
      .frame8New{
        margin-bottom: 10px;
      }
    }
    
    .frame8__ .overlap-group {
      background-color: #f9fafb;
      border-bottom: 1px solid;
      border-color: #d0d5dd;
      border-radius: 10px 10px 0px 0px;
      height: 36px;
      left: 0;
      top: 0;
      width: 100%;
    }
    
    .frame8__ .text-wrapper {
      color: #475467;
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      left: 15px;
      letter-spacing: 0;
      line-height: 36px;
      text-align: center;
      /* margin-top: 9px; */
      white-space: nowrap;
      transition: all 0.5s ease
    }


    .frame8__ .text-wrapperNano {
      color: #475467;
      font-family: "Inter", Helvetica;
      font-size: 11px;
      font-weight: 500;
      left: 15px;
      letter-spacing: 0;
      line-height: 36px;
      text-align: center;
      /* margin-top: 9px; */
      white-space: nowrap;
      transition: all 0.5s ease
    }
    
    .frame8__ .element-CHF {
      color: transparent;
      font-family: "Inter", Helvetica;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }
    
    .total_head{
      color: transparent;
      font-family: "Inter", Helvetica;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
      text-align: start;
      color : #1D2939;
    }
    
    .name_titel{
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
      text-align: start;
      color : #475467;
    }
    
    .name_titel2{
      font-family: "Inter", Helvetica;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 36px;
      text-align: start;
      color : #475467;
    }

    .name_titel{
      font-family: "Inter", Helvetica;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: start;
      color : #475467;
    }

    .name_titel2{
      font-family: "Inter", Helvetica;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: start;
      color : #475467;
    }


    .name_titel22222{
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: start;
      color : #475467;
      transition: all 0.5s ease
    }


    .name_titel22222Nano{
      font-family: "Inter", Helvetica;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: start;
      color : #475467;
      transition: all 0.5s ease
    }
    
    .name_value{
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 23px;
      text-align: end;
      color : #344054;
    }
    
    .name_value2{
      font-family: "Inter", Helvetica;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-align: end;
      color : #344054;
    }

    .name_title_bestseller{
      font-family: "Inter", Helvetica;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 29px;
      text-align: start;
      color : #475467;
    }

    .name_value_bestseller{
      font-family: "Inter", Helvetica;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 29px;
      text-align: end;
      color : #344054;
    }
    
    .total_sum{
      font-family: "Inter", Helvetica;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-align: end;
      color : #039855;
    }
    
    .frame8__ .span {
      color: #000000;
      line-height: 48px;
    }
    
    .frame8__ .text-wrapper-2 {
      color: #000000;
    }
    
    .frame8__ .text-wrapper-3 {
      color: #667085;
      font-size: 14px;
      margin-top: -25px;
      text-align: center;
  }
    
  
    .frame8New {
      border: 1px solid;
      border-color: #d0d5dd;
      border-radius: 10px;
      height: 503px;
      position: relative;
      width: 100%;
    }

    .frame8New2{
      border: 1px solid;
      border-color: #d0d5dd;
      border-radius: 10px;
      height: 360px;
      position: relative;
      width: 100%;
    }
    
    .frame8New .overlap-group {
      background-color: #f9fafb;
      border-bottom: 1px solid;
      border-color: #d0d5dd;
      border-radius: 10px 10px 0px 0px;
      height: 36px;
      left: 0;
      top: 0;
      width: 100%;
    }


    .frame8New2 .overlap-group {
      background-color: #f9fafb;
      border-bottom: 1px solid;
      border-color: #d0d5dd;
      border-radius: 10px 10px 0px 0px;
      height: 36px;
      left: 0;
      top: 0;
      width: 100%;
    }
    
    .frame8New .text-wrapper {
      color: #475467;
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      left: 15px;
      letter-spacing: 0;
      line-height: 36px;
      white-space: nowrap;
      margin-left: 15px;
    }


    .text-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }


    .frame8New2 .text-wrapper {
      color: #475467;
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      left: 15px;
      letter-spacing: 0;
      line-height: 36px;
      white-space: nowrap;
      margin-left: 15px;
    }
    
    .date_ {
      color: #667085;
      font-family: "Inter", Helvetica;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
      white-space: nowrap;
      margin-top: 10px;
  }

    .border-right {
      border-right: 1px solid #D0D5DD; 
  }

    .staff_count{
        color: transparent;
        font-family: "Inter", Helvetica;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        color: #1D2939;
    }

    .name_titel_tender{
      color: #101828;
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 36px;
      white-space: nowrap; 
    }

    .name_titel_tenderNano{
      color: #101828;
      font-family: "Inter", Helvetica;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 36px;
      white-space: nowrap; 
    }
    
    .frame8New .element-CHF {
      color: transparent;
      font-family: "Inter", Helvetica;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
      margin-top: 25px;
    }
    
    
    .frame8New .span {
      color: #000000;
      line-height: 38px;
    }
    
    .frame8New .text-wrapper-2 {
      color: #000000;
    }
    
    .frame8New .text-wrapper-3 {
      color: #667085;
      font-size: 20px;
      line-height: 38px;
    }


    .frame8New2 .span {
      color: #000000;
      line-height: 38px;
    }
    
    .frame8New2 .text-wrapper-2 {
      color: #000000;
    }
    
    .frame8New2 .text-wrapper-3 {
      color: #667085;
      font-size: 20px;
      line-height: 38px;
    }
    
    .card_das{
        border-radius: 0px 0px 8px 8px !important;
        box-shadow: none !important;
        border: 1px solid #D0D5DD !important
    }
    
    .custom-nav .nav-item .nav-link {
      color: #667085;
      border-radius: 0; 
      cursor: pointer;
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      text-align: center;
      padding: 6px 30px 6px 30px;
    }
    
    .custom-nav .nav-item .nav-link.active {
      font-family: "Inter", Helvetica;
      font-size: 14px;
      font-weight: 500;
      color: #F25042;
      letter-spacing: 0;
      line-height: 25px;
      text-align: center;
      background-color: #fff;
      border-bottom: 1px solid #fff;
      padding: 6px 30px 6px 30px;
    }
    
    .tab-contentDash {
      padding: 10px;
      border: 1px solid #ccc;
      background-color: #fff;
      margin-top: -1px; /* Remove gap between nav and content */
      border-radius: 0px 8px 8px 8px !important;
      box-shadow: none !important;
      border: 1px solid #D0D5DD !important
    }
    
    
    
    
    