.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: rgb(135, 133, 133) !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: black !important;
}

.css-u9osun.Mui-error {
  color: rgb(13, 13, 13) !important;
}

.hiztcv.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #0b0b0b !important;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
