.title_text{
color: rgb(102, 112, 133);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}


