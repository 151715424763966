.css-6j8wv5-Input {
      font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    }
    
    .css-ackcql{
      font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    }


    aside {
      overflow-x: hidden;
      width: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #fff !important;
      transition: all 0.3s ease-in-out;
    }
    
    .drawerMin {
      width: 65px;
    }
    .drawerOpen {
      width: 250px;
    }
    
    main {
      position: absolute;
      top: 80px;
      padding: 1em;
      margin-left: 0;
      bottom: 0;
      /* overflow-y: hidden; */
      transition: all 0.3s ease-in-out;
    }

    .main_{
      position: initial;
      /* top: 80px; */
      /* padding: 0em; */
      margin-left: 0;
      bottom: 0;
      overflow-y: hidden;
      transition: all 0.3s ease-in-out;
    }

    .mainMin {
      margin-left: 80px;
    }
    .mainOpen {
      margin-left: 180px;
    }


    .mainMinCustome {
      margin-left: -187px;
    }
    .mainOpenCustome {
      margin-left: -5px;
    }

    .mainMinDetails {
      margin-left: -185px;
    }
    .mainOpenDetails {
      margin-left: 0px;
    }


    @media screen and (max-width: 767px) {
      .mainMinDetails {
        margin-left: 0px; /* Adjust the value as needed for better mobile view */
      }
      .mainMinCustome {
        margin-left: 0px;
      }
    }


    @media screen and (max-width: 767px) {
      #kt_content_container {
          margin-right: 0 !important;
          margin-left: 0 !important;
    }}

    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


    /* .grid__Chart {
      display: grid;
      gap: 1em;
      grid-template-rows: min-content minmax(0, 1fr) min-content;
      grid-template-columns: 1fr minmax(0, 3fr);
      grid-template-areas: "a h"
                           "a c"
                           "a f";
      height: calc(100vw - 2em);
      width: calc(100vw - 2em);
      margin: 1em;
    } */