.current {
      font-size: 14px;
      font-weight: 500;
      color: #475467;
      margin-left: 20px;
      margin-top: 10px;
    }
    
    .plan-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 7px 20px 0;
  }
    
    .year {
      font-size: 24px;
      font-weight: 500;
      color: #000;
    }
    
    .plan-status {
      text-align: end;
    }
    
    .renew {
      font-size: 14px;
      font-weight: 500;
      color: #475467;
      margin-left: 20px;
    }
    