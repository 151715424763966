.menu_title {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  color: #626161;
}

.icon_ {
  font-size: 25px;
  line-height: 1.5 !important;
}

.icon2_ {
  font-size: 25px;
  line-height: 1.5 !important;
}

.icon2_:hover {
  color: #f25042;
}



.css-106c1u2-MuiBadge-badge {
  background-color: #F25042 !important;
  font-size: 0.90rem !important;  
}

.css-zhwrlg {
  background-color: #F25042 !important;
  font-size: 0.90rem !important;  
}

