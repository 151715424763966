.WhateverYourNavIs {
  height: calc(100vh - 9rem);
  overflow-y: auto;
}

.arrowDown_ {
  border-top: 1px solid rgb(199, 208, 215) !important;
  position: absolute !important;
  bottom: 0px !important;
  padding: 8px !important;
  background-color: rgb(255, 255, 255);
  height: 32px !important;
  width: 65px !important;
  border-right: 1px solid rgb(199, 208, 215);
  transition: all 0.5s ease;
}

.arrowDown_Hide{
  border-top: 1px solid rgb(199, 208, 215) !important;
  position: absolute !important;
  bottom: 0px !important;
  padding: 8px !important;
  background-color: rgb(255, 255, 255);
  height: 32px !important;
  width: 260px !important;
  border-right: 1px solid rgb(199, 208, 215);
  transition: all 0.5s ease;
}

.icon_s{
  color: #344054;
  /* margin-right: 8px; */
  font-size: 23px ; 
  transition: all 0.5s ease;
} 

.icon_active{
  color: #fff;
  margin-left: -2px;
  font-size: 23px ;
  transition: all 0.5s ease;
}

.padding_{
  padding-left: 28px !important;
}

.hide{
  font-size: 16px;
  color: #667085;
  letter-spacing: 0.2px;
  line-height: 19px;
  transition: all 0.5s ease;
}

