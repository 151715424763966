.title{
      font-size: 16px;
      color: #3F4254;
      font-weight: 600;
}

.Name_{
      font-size: 16px;
      color: #667085;
      font-weight: 400;
}

.table-header {
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      color: #667085;
    }
