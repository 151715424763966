@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");

/* .loader_kpgpt {
  display: inline-block;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4caf50;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
} */

.receiver-message:after{
  border-right: 13px solid #f5f5f5 !important;
}

.sender-message:after{
  border-left: 13px solid #F25042 !important;
}

.sender-message {
  background-color: #F25042 !important; /* Set your desired background color */
  color: #ffffff !important; /* Set your desired text color */
}

.receiver-message {
  background-color: #f5f5f5 !important;  /* Set your desired background color */
  color: black !important; /* Set your desired text color */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chat-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 90px;
  border-radius: 5px;
}
.chat-container {
  background-color: #ffffff;
  box-shadow: 0 0 4px #e6e6e6;
  border-radius: 5px;
}

.chat-header {
  padding: 10px 15px;
  text-align: center;
  background-color: #F25042;
  border-radius: 5px 5px 0 0;
}

.chat-header .title {
  font-size: 20px;
  text-transform: capitalize;
  margin: 0;
  color:#ffffff
}

.chat-body {
  background-color: #ffffff;
  min-height: 580px;
  height: calc(100vh - 290px);
  overflow: hidden;
  padding: 12px;
  overflow-y: auto;
}

.chat-footer .send-btn {
  font-weight: 500;
  border: none;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  background: #ededed;
  min-width: 60px;
}

.message-item {
  display: flex;
}

.message {
  max-width: 70%;
  padding: 10px;
  margin: 10px;
  clear: both;
}

.message p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  word-break: break-all;
}

.sender-message {
  position: relative;
  background-color: #ededed;
  /* float: right; */
  border-radius: 5px 0px 5px 5px;
  margin-left: auto;
}

.sender-message:after {
  position: absolute;
  content: "";
  right: -13px;
  top: 0;
  border-top: 0px solid transparent;
  border-left: 13px solid #ededed;
  border-bottom: 10px solid transparent;
}

.receiver-message {
  position: relative;
  background-color: #5558ff;
  float: left;
  color: #ffffff;
  border-radius: 0px 5px 5px 5px;
}

.message.receiver-message {
  margin-left: 12px;
}

.receiver-message:after {
  position: absolute;
  content: "";
  left: -13px;
  top: 0;
  border-top: 0px solid transparent;
  border-left: none;
  border-bottom: 10px solid transparent;
  border-right: 13px solid #5558ff;
}

.chat-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.chat-footer input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  background: #ededed;
  border: none;
  border-radius: 5px;
  height: 40px;
  outline: none;
}

.typing-message {
  position: relative;
  background-color: #5558ff;
  float: left;
  color: #ffffff;
  border-radius: 0px 5px 5px 5px;
}
.typing-message p {
  line-height: normal;
}
/* .typing-message:after {
  position: absolute;
  content: "";
  left: -13px;
  top: 0;
  border-top: 0px solid transparent;
  border-left: none;
  border-bottom: 10px solid transparent;
  border-right: 13px solid #5558ff;
} */

.typing-message span {
  /* content: ""; */
  display: inline-block;
  animation: dots-blink 1.5s infinite;
  animation-fill-mode: both;
  width: 10px;
  height: 10px;
  background: #ffffff;
  /* position: absolute;
  left: 0;
  top: 0; */
  border-radius: 50%;
}

.typing-message span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  margin-left: 5px;
}

.typing-message span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin-left: 5px;
}

@keyframes dots-blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.img-chatbot {

  width: 42px;
  height: 42px;

}
