@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Kaushan+Script&family=Sriracha&display=swap');


.container_gride_view {
      height: 100%;
      padding: 0 !important;
      width: 100%;
    }
    
    .container_gride_view .img-container {
      width: 100%;
      height: 100% !important;
    }
    
    .container_gride_view .img-container img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 19px 19px 0px 0px !important
    }



      @media (max-width: 768px){
      .container_gride_view {
            width: 100%;
            height: 200px;
      }}

      @media (max-width: 768px){
      .container_gride_view .img-container {
        width: 100%;
            height: 200px;
      }}

      @media (max-width: 768px){
      .container_gride_view .img-container img  {
      width: 100%;
      height: 100%;
      object-fit: cover;
      }}


      .svg_ribin{
      /* margin-top: -65px; */
      }

      @media (max-width: 768px){
      .svg_ribin  {
      /* margin-top: -38px; */
      }
      .CHF_{
        font-size: 16px !important
      }
      .dear_name{
        font-size: 14px !important
      }.code{
        font-size: 12px !important;
      }.validity{
        font-size: 12px !important;
      }

    }

    .gift_voucher{
      /* font-family: 'Calibri', sans-serif; */
      font-family: 'Sriracha', cursive;
      font-size: 23px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(140, 11, 4, 1);
      /* margin-top: -64px; */
    }

    .dear_name{
      color: #000;
      font-family: 'Sriracha', cursive;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      /* line-height: 40px;s */
    }

    .CHF_{
      color: #000;
      text-align: right;
      font-family: 'Sriracha', cursive;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      /* line-height: 40px; */
    }


    .your_message{
      color: #666;
      font-family: 'Sriracha', cursive;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 4.5px; 
      text-transform: capitalize;
    }
    

    .code{
      color: #000;
      text-align: right;
      font-family: 'Sriracha', cursive;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.24px;
      text-transform: uppercase;
    }

    .validity{
      color: #666;
      text-align: right;
      font-family: 'Sriracha', cursive;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: -12px;
    }

    .Branch_selection{
      color: var(--gray-700, #344054);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; 
    }

    .common_line{
      color: var(--gray-500, #667085);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
    }

    /* .parent {
      position: relative;
      top: 0;
      left: 0;
    }
    .image1 {
      position: relative;
      top: 0;
      left: 0;
      border: 1px red solid;
    }
    .image2 {
      position: absolute;
      top: 30px;
      left: 30px;
      border: 1px green solid;
    } */

    .coupon-and-promotion {
      width: 440px;
      height: 290px;
      background-color: #FFFFFF;
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid;
      border-color: #D0D5DD;
    }
    .coupon-and-promotion .overlap {
      position: relative;
      width: 450px;
      height: 276px;
    }
    .coupon-and-promotion .rectangle {
      position: absolute;
      width: 440px;
      height: 140px;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    .coupon-and-promotion .frame {
      position: absolute;
      width: 151px;
      height: 69px;
      top: 175px;
      left: 269px;
    }
    .coupon-and-promotion .text-wrapper {
      position: absolute;
      top: 14px;
      left: 56px;
      font-family: 'Sriracha', cursive;
      font-weight: 400;
      color: #000000;
      font-size: 28px;
      text-align: right;
      letter-spacing: 0;
      line-height: 40px;
      white-space: nowrap;
    }
    .coupon-and-promotion .div {
      position: absolute;
      top: 60px;
      left: 26px;
      font-family: 'Sriracha', cursive;
      font-weight: 400;
      color: #000000;
      font-size: 12px;
      text-align: right;
      letter-spacing: 0.24px;
      line-height: 24px;
      white-space: nowrap;
    }
    .coupon-and-promotion .frame-2 {
      position: absolute;
      width: 151px;
      height: 69px;
      top: 175px;
      left: 20px;
    }
    .coupon-and-promotion .text-wrapper-2 {
      position: absolute;
      top: 188px;
      left: 20px;
      font-family: 'Sriracha', cursive;
      font-weight: 400;
      color: #000000;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 40px;
      white-space: nowrap;
    }
    .coupon-and-promotion .lectus-leo-semper {
      position: absolute;
      width: 227px;
      top: 230px;
      left: 20px;
     font-family: 'Sriracha', cursive;
      font-weight: 400;
      color: #666666;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 15.5px;
    }
    .coupon-and-promotion .frame-3 {
      position: absolute;
      width: 400px;
      height: 20px;
      top: 252px;
      left: 20px;
    }
    .coupon-and-promotion .text-wrapper-3 {
      position: absolute;
      top: 256px;
      left: 307px;
      font-family: 'Sriracha', cursive;
      font-weight: 400;
      color: #666666;
      font-size: 12px;
      text-align: right;
      letter-spacing: 0;
      line-height: 20px;
      white-space: nowrap;
    }
    .coupon-and-promotion .group {
      position: absolute;
      width: 450px;
      height: 101px;
      top: 82px;
      left: 0;
    }
    .coupon-and-promotion .overlap-group {
      position: relative;
      height: 105px;
    }
    .coupon-and-promotion .frame-4 {
      position: absolute;
      width: 450px;
      height: 16px;
      top: 42px;
      left: 0;
      background-color: var(--fireside);
      border-radius: 2px;
      transform: rotate(180deg);
      box-shadow: 0px 2px 0px #00000033;
    }
    .coupon-and-promotion .union {
      position: absolute;
      width: 112px;
      height: 65px;
      top: 40px;
      left: 313px;
    }
    .coupon-and-promotion .img {
      position: absolute;
      width: 111px;
      height: 49px;
      top: 0;
      left: 313px;
    }
    .coupon-and-promotion .vector {
      position: absolute;
      width: 27px;
      height: 33px;
      top: 26px;
      left: 356px;
    }
    .coupon-and-promotion .text-wrapper-4 {
      position: absolute;
      top: 148px;
      left: 20px;
      font-family: 'Sriracha', cursive;
      font-weight: 400;
      color: #8C0B04;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 40px;
      white-space: nowrap;
    }


    