.heading{
      font-size: 18px;
      letter-spacing: 0.1px;
      font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      color: rgb(16, 24, 40);
      font-weight: 600;
  }

  .discriptionline{
      font-size: 14px;
      letter-spacing: 0.1px;
      font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      color: #475467; 
      font-weight: 400;
  }

  .form_label_{
      font-size: 14px;
      letter-spacing: 0.1px;
      font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      color: #344054; 
      font-weight: 400; 
      margin-bottom: 5px;
  }