/* //////////// paginamtion height */
.css-10gei56 {
  min-height: 5rem !important
}

.css-zrlv9q {
  position: inherit !important;
  /* margin: 10px 0px 10px 0px !important */
}


/* /////////////// Pagination button selected color */
.css-iqeo3-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #F25042 !important;
  border: 1px solid #F25042 !important;
  background-color: #FEF3F2 !important;
}

.css-9c3t4q.Mui-selected {
  color: #F25042 !important;
  border: 1px solid #F25042 !important;
  background-color: #FEF3F2 !important;
}

/* /////////////// Pagination button */
.css-iqeo3-MuiButtonBase-root-MuiPaginationItem-root {
  max-width: 35px !important;
  height: 34px !important;
  font-size: 14px !important;
  margin: 4px 4px !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

/* ////////? hover button background color
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
        background-color: transparent !important;
    } */

.button_CSV_ {
  border: 1px solid #D0D5DD !important;
  border-color: #50cd89 !important;
  text-transform: none !important;
  font-size: 13px !important;
  color: #50cd89 !important;
  height: 38px !important;
  border-radius: 8px !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.button_CSV_:hover {
  color: #fff !important;
  background-color: #50cd89 !important;
  border-color: #50cd89 !important;
}

.button_group {
  border: 1px solid #D0D5DD !important;
  text-transform: none !important;
  font-size: 13px !important;
  color: #344054 !important;
  height: 38px !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.buttonDisplay {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.buttonFilter {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border-right-color: transparent;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}


/* /////////////// Search bar */

.css-1v8abvc-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 16px !important;
  border-radius: 10px !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}


.css-1k3q1yi {
  font-size: 16px !important;
  border-radius: 10px !important;
  margin: 9px 0px 0px 5px !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.css-11sh9fj-MuiFormControl-root-MuiTextField-root {
  margin: 9px 0px 0px 5px !important
}

.css-12yjm75-MuiInputBase-input-MuiOutlinedInput-input {
  margin: 0px 0px 0px 14px !important;
  color: #344054 !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  width: 360px !important;
}

.css-1y3zh1 {
  margin: 0px 0px 0px 14px !important;
  color: #344054 !important;
  width: 360px !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.css-1laqsz7-MuiInputAdornment-root {
  display: none !important;
}

.css-1nvf7g0 {
  display: none !important;
}

.chips_active {
  font-size: 13px !important;
  height: 22px !important;
  background: #F25042 !important;
  color: #fff !important;
  border: 0px !important;
  font-weight: 500 !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.chips {
  font-size: 13px !important;
  height: 22px !important;
  background: #667085 !important;
  color: #fff !important;
  border: 0px !important;
  font-weight: 500 !important;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.navlinkName {
  font-size: 18px;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 500;
  line-height: 1px;
  letter-spacing: 0.5px;
}

.nav__ {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* ////////////////////// Filter Dot remove css ////////////////*/
.css-1p8dotb-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}

.css-pxbfn8 {
  display: none !important;
}

.email_ {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  color: #344054 !important;
  font-size: 16px !important;
}


.delete_ {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  color: rgb(217, 45, 32) !important;
  font-size: 16px !important;
}


/* //////////////////////// table layout ////////////////// */

.css-1atu56z-MuiPaper-root {
  border-radius: 0px 0px 8px 8px !important;
  box-shadow: none !important;
  border: 1px solid #D0D5DD !important;
}

.css-d7xo1i {
  border-radius: 0px 0px 8px 8px !important;
  box-shadow: none !important;
  border: 1px solid #D0D5DD !important;
}

/* ///////////////////////////  table checkbox   /////////////////////////////// */
/* .css-5qyhmh-MuiTableCell-root {
    text-align: center !important;
  }

  .css-kpt86b-MuiTableCell-root{
    text-align: center !important;
  }

  .css-1inyl0k {
    text-align: center !important;
  }

  .css-1su8iyw{
    text-align: center !important;
  } */

/* .Mui-TableHeadCell-Content css-1w86f15{
    width: 0px !important;
  } */
/* .css-h5odmr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-h5odmr-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: #F25042 !important;
  } */

.css-h5odmr-MuiButtonBase-root-MuiCheckbox-root {
  color: #F25042 !important;
}

.css-15dqp9w {
  color: #F25042 !important;
}

.css-bbxzxe {
  display: none in !important;
}