.checkboxname{
      font-size: 14px !important;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    font-weight: 500 !important;
    line-height: 1px !important;
    letter-spacing: 0.5px !important;
    color: #344054 !important;
}

.checkbox{
      color:  #F25042 !important;
}