.main_div{
      border-radius: 10px;
    border: 1px solid var(--gray-300, #D0D5DD);
    margin-bottom: 25px
}

.main_second_div{
      padding: 16.5px 0px 16.5px 16px;
      align-items: center;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid rgb(208, 213, 221);
      background: rgb(249, 250, 251);
  }

  .textname{
    color: rgb(102, 112, 133);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .contan_div{
      display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-left: 10px;
  }

  .contan_dev_2{
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      border-radius: 4px 
  }

  .name_style{
      font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }


  @media screen and (max-width: 767px) {
    .contan_dev_2 {
      width: 9px;
      height: 9px;
      flex-shrink: 0;
      border-radius: 4px;
    }.name_style {
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
  }
}